import { useEffect, useState } from "react"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { useNavigate, useParams } from "react-router-dom"
import { emailDetailsDisplayColumns, smsDetailsDisplayColumns } from "../CommunicationDashboard/hardcodeData"
import "./styles.scss"

import { useMutateData } from "../../../utils/hooks"
import { getCommunicationDataUrl } from "../../../api/endpoints/communication"

const CommunicationDetails = (props) => {
  const { type } = props
  const { id } = useParams()
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  const [details, setDetails] = useState(null)
  const [loading, setLoading] = useState(false)
  const [apiCall] = useMutateData()

  const fetchDetails = async () => {
    setLoading(true)
    try {
      const response = await apiCall({
        url: getCommunicationDataUrl({ queryParamString: new URLSearchParams({ id }) }),
        method: "GET",
      })
      const data = await response.data
      setDetails(data?.communication_logs_data?.[0] || {})
    } catch (error) {
      console.error("Error fetching details:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDetails()
  }, [id])
  const detailsDisplayColumns = type === "EMAIL" ? emailDetailsDisplayColumns : smsDetailsDisplayColumns

  const pageTitle = type === "EMAIL" ? "Email Details" : "SMS Details"
  return (
    <div className="communication-details-page-container">
      <div className="white-bg  communication-container">
        <div className="communication-heading-container">
          <div className="communication-heading-text-section">
            <div className="communication-heading-back-button-container">
              <ArrowLeftOutlined className="communication-details-page-back-button" onClick={goBack} />
              <span className="communication-details-page-heading">{pageTitle}</span>
            </div>
          </div>

          <div className="communication-body-container">
            <div className="communication-details-event-row">
              {loading && <p>Loading...</p>}
              {details &&
                detailsDisplayColumns.map((column) => (
                  <div key={column?.id} className="communication-details-dataBlock">
                    <span className="communication-details-label text-bold">{column?.label} :</span>
                    {column?.key === "body" ? (
                      <span
                        className="communication-details-value"
                        dangerouslySetInnerHTML={{ __html: details[column?.key] }}
                      />
                    ) : (
                      <span className="communication-details-value">{details[column?.key]}</span>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommunicationDetails
