import React, { useEffect, useState } from "react"
import { Button, Input, Modal, notification } from "antd"
import { useLocation } from "react-router-dom"
import { DoctorConsentForm } from "./DoctorConsentForm"
import { fetchFormDataUrl, sendOTPUrl, verifyOTPUrl } from "../../../api/endpoints"
import "./styles.scss"
import { useMutateData } from "../../../utils/hooks"
import { COUNTDOWN_DURATION_SECONDS, DOCTOR_CONSENT_URL_REGEX_PATTERN } from "./constants"
import { Loader } from "../../../components/Loader"

/* eslint-disable camelcase */
export const DoctorConsentFormPage = () => {
  const { pathname } = useLocation()
  const [apiCall] = useMutateData()
  const [doctorId, setDoctorId] = useState(0)
  const [programId, setProgramId] = useState(0)
  const [applicationId, setApplicationId] = useState(0)
  const [applicationUid, setApplicationUid] = useState("")
  const [loader, setLoader] = useState(true)
  const [modalLoader, setModalLoader] = useState(true)
  const [fetchFormResponse, setFetchFormResponse] = useState(null)
  const [showOTPModal, setShowOTPModal] = useState(false)
  const [isOTPSentSuccessfully, setIsOTPSentSuccessfully] = useState(false)
  const [otpValue, setOtpValue] = useState("")
  const [formData, setFormData] = useState()
  const [countdown, setCountdown] = useState(COUNTDOWN_DURATION_SECONDS)
  const [isFormDataFetched, setIsFormDataFetched] = useState(false)
  const [toastMessage, contextHolder] = notification.useNotification()
  const [disableAcceptButton, setDisableAcceptButton] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isDoctorConsentAdded, setIsDoctorConsentAdded] = useState(false)

  const extractURLParams = () => {
    const regexPattern = DOCTOR_CONSENT_URL_REGEX_PATTERN
    const match = pathname.match(regexPattern)

    if (match && match[1]) {
      const base64UrlParam = match[1]
      const decodedData = window.atob(base64UrlParam)
      const extractedData = JSON.parse(decodedData)
      const { doctor_id, program_id, application_uid } = extractedData
      setApplicationId(Number(extractedData?.applicationId))
      setDoctorId(doctor_id)
      setProgramId(program_id)
      setApplicationUid(application_uid)
    } else {
      console.log("Base64 parameter not found in URL")
    }
  }

  const startCountdown = () => {
    setCountdown(COUNTDOWN_DURATION_SECONDS)
  }

  const decreaseCountdown = () => {
    setCountdown((prevCountdown) => prevCountdown - 1)
  }

  useEffect(() => {
    extractURLParams()
  }, [])

  useEffect(() => {
    if (showOTPModal && isOTPSentSuccessfully) {
      const timer = setInterval(() => {
        if (countdown > 0) {
          decreaseCountdown()
        } else {
          clearInterval(timer)
        }
      }, 1000)
      return () => clearInterval(timer)
    }
    return () => {}
  }, [countdown, showOTPModal, isOTPSentSuccessfully])

  const fetchFormData = async () => {
    const payload = {
      doctor_id: doctorId,
      program_id: programId,
      applicationId,
      application_uid: applicationUid,
    }
    try {
      const response = await apiCall({
        url: fetchFormDataUrl({ queryParamString: new URLSearchParams(payload) }),
        method: "GET",
      })
      if (response?.data && response?.status === 200) {
        setFetchFormResponse(response?.data)
        setLoader(false)
        setIsDoctorConsentAdded(response?.data?.doctor_consent_already_added)
      }
    } catch (err) {
      toastMessage.error({
        key: "fetchFormDataError",
        message: "error",
        description: err,
      })
    }
  }

  useEffect(() => {
    if (doctorId && programId && !isFormDataFetched) {
      fetchFormData()
      setIsFormDataFetched(true)
    }
  }, [doctorId, programId, isFormDataFetched])

  const sendFormValues = (formDataValues) => {
    setFormData(formDataValues)
  }

  const openOTPModal = () => {
    setShowOTPModal(true)
  }

  const closeOTPModal = () => {
    setOtpValue("")
    setShowOTPModal(false)
    setIsOTPSentSuccessfully(false)
    setCountdown(COUNTDOWN_DURATION_SECONDS)
  }

  const sendOTP = async () => {
    const payload = {
      doctor_id: doctorId,
      program_id: programId,
      applicationId,
      application_uid: applicationUid,
    }

    try {
      const responseData = await apiCall({
        url: sendOTPUrl,
        method: "POST",
        payload,
      })

      if (responseData?.status === 200) {
        toastMessage.success({
          key: "sendOTPSuccess",
          message: "success",
          description: responseData?.data?.message,
        })
        setModalLoader(false)
        setIsOTPSentSuccessfully(responseData?.status === 200)
      } else {
        toastMessage.error({
          key: "sendOTPError",
          message: "error",
          description: "Error sending OTP",
        })
      }
    } catch (err) {
      toastMessage.error({
        key: "sendOTPError",
        message: "error",
        description: err,
      })
    }
  }

  const verifyOTPandSubmitData = async () => {
    const payload = {
      otp: otpValue,
      doctor_id: doctorId,
      program_id: programId,
      form_data: formData,
      applicationId,
      application_uid: applicationUid,
    }

    try {
      const responseData = await apiCall({
        url: verifyOTPUrl,
        method: "POST",
        payload,
      })

      if (responseData?.status === 200) {
        toastMessage.success({
          key: "verifyOTPandSubmitDataSuccess",
          message: "success",
          description: responseData?.data?.message,
        })
        setShowOTPModal(false)
        setDisableAcceptButton(true)
        setIsOTPSentSuccessfully(false)
      }
    } catch (err) {
      toastMessage.error({
        key: "verifyOTPandSubmitDataError",
        message: "error",
        description: err?.response?.data?.error?.message,
      })
    }
  }

  const handleSubmitButtonClick = () => {
    openOTPModal()
    sendOTP()
  }

  const handleOtpChange = (e) => {
    setOtpValue(e.target.value)
  }

  const handleOtpSubmit = () => {
    verifyOTPandSubmitData()
    setOtpValue("")
  }

  const resendOtp = () => {
    setIsOTPSentSuccessfully(false)
    sendOTP()

    if (isOTPSentSuccessfully) startCountdown()
  }

  const handleFormValidationChange = (isValid) => {
    setIsFormValid(isValid)
  }

  const renderResendOTPSection = () => (
    <div>
      <Button
        disabled={countdown > 0}
        className={
          countdown > 0
            ? "doctor-consent-resend-otp-button-inactive"
            : "doctor-consent-resend-otp-button-active"
        }
        onClick={resendOtp}
      >
        <span>Resend OTP</span>
      </Button>
      <span>Wait for {countdown} seconds to re-send OTP</span>
    </div>
  )

  const renderOTPModal = () => (
    <div className="doctor-consent-otp-modal-container">
      <Modal
        title="OTP Verification"
        open={showOTPModal}
        onOk={handleOtpSubmit}
        onCancel={closeOTPModal}
        closable={false}
        okText="Submit"
        wrapClassName="doctor-consent-otp-modal-container"
      >
        {modalLoader && <Loader size={20} />}
        {!modalLoader && (
          <div>
            <Input
              placeholder="Enter OTP"
              value={otpValue}
              onChange={handleOtpChange}
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              onKeyDown={(e) => {
                if (
                  !/^\d$/.test(e.key) &&
                  e.key !== "Backspace" &&
                  e.key !== "Delete" &&
                  e.key !== "ArrowLeft" &&
                  e.key !== "ArrowRight"
                ) {
                  e.preventDefault()
                }
              }}
            />
            <div className="doctor-consent-otp-resend-container">{renderResendOTPSection()}</div>
          </div>
        )}
      </Modal>
    </div>
  )

  return (
    <div className="doctor-consent-page-container">
      {contextHolder}
      {loader && <Loader type="fullPage" size={40} />}
      {fetchFormResponse && (
        <DoctorConsentForm
          response={fetchFormResponse}
          sendFormValues={sendFormValues}
          onFormValidationChange={handleFormValidationChange}
        />
      )}
      <Button
        disabled={isDoctorConsentAdded || disableAcceptButton || !isFormValid}
        className="doctor-consent-submit-button"
        onClick={handleSubmitButtonClick}
      >
        I ACCEPT
      </Button>
      {showOTPModal && renderOTPModal()}
    </div>
  )
}
