import { useEffect, useState, useRef } from "react"
import { Row, Col, Input, Form, Button, Typography } from "antd"
import { MailOutlined } from "@ant-design/icons"
import { validationRules } from "./constants"
import { OTP_RESEND_TIME } from "../LoginForm/constants"

const { Text } = Typography

const preventNonNumbers = (e) => {
  const isNonNumber = e.which < 48 || e.which > 57
  const allowedActions = e.which === 8 || e.which === 9 || e.which === 13 || e.which === 46
  const prevent = isNonNumber && !allowedActions
  if (prevent) {
    e.preventDefault()
  }
  const isEmpty = e?.target?.value?.trim().length === 0
  const currentElementId = Number(e?.target?.id?.replaceAll("otp", "")) || ""
  const isFirstElement = currentElementId === 1
  const isBackspace = e.which === 8
  if (isEmpty && isBackspace) {
    if (!isFirstElement) {
      const previousElementId = `otp${currentElementId - 1}`
      document.getElementById(previousElementId).focus()
      document.getElementById(previousElementId).select()
    }
  }
  return !prevent
}
const onOtpDigitChange = (event) => {
  const currentElementId = Number(event?.target?.id?.replaceAll("otp", "")) || ""
  const isFirstElement = currentElementId === 1
  const isLastElement = currentElementId === 6
  if (currentElementId && !isLastElement) {
    const hasReachedMaxLength = event?.target?.value?.trim().length >= 1 || false
    if (hasReachedMaxLength) {
      const nextElementId = `otp${currentElementId + 1}`
      document.getElementById(nextElementId).focus()
      document.getElementById(nextElementId).select()
    }
  }
  if (currentElementId && !isFirstElement) {
    const hasBeenDeleted = event?.target?.value?.trim().length === 0 || false
    if (hasBeenDeleted) {
      const previousElementId = `otp${currentElementId - 1}`
      document.getElementById(previousElementId).focus()
      document.getElementById(previousElementId).select()
    }
  }
}
export const OTP = ({
  otpSuccessMessage = "",
  otpError = "",
  resendOtp,
  widthValue = 6,
  resendOtpContainerWidth = 4,
}) => {
  const [resendOtpTimer, setResendOtpTimer] = useState(OTP_RESEND_TIME)
  const otpTimerRef = useRef(null)
  const getResendTimer = () => (resendOtpTimer > 0 ? `Resend in ${resendOtpTimer}s` : "Resend")
  useEffect(() => {
    otpTimerRef.current = setInterval(() => {
      setResendOtpTimer((value) => (value > 0 ? value - 1 : value))
    }, 1000)
    return () => {
      clearInterval(otpTimerRef.current)
    }
  }, [])
  const resendOtpCallback = async () => {
    if (resendOtpTimer <= 0) {
      await resendOtp()
      setResendOtpTimer(OTP_RESEND_TIME)
    }
  }
  return (
    <>
      <Row className="mt-16 w-100">
        <Col span={12} className="otp-fields-label">
          <Text>Enter the verification code here</Text>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={widthValue} xxl={widthValue}>
          <Row gutter={[12, 12]} className="mt-8" justify="space-between">
            <Col span={2}>
              <Form.Item name="otp1" key="otp1" rules={validationRules}>
                <Input
                  className="login-otp-input"
                  onChange={onOtpDigitChange}
                  onKeyDown={preventNonNumbers}
                  maxLength={1}
                  inputMode="numeric"
                  type="number"
                  pattern="[0-9]+"
                  autoFocus
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="otp2" key="otp2" rules={validationRules}>
                <Input
                  className="login-otp-input"
                  onChange={onOtpDigitChange}
                  onKeyDown={preventNonNumbers}
                  maxLength={1}
                  inputMode="numeric"
                  type="number"
                  pattern="[0-9]+"
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="otp3" key="otp3" rules={validationRules}>
                <Input
                  className="login-otp-input"
                  onChange={onOtpDigitChange}
                  onKeyDown={preventNonNumbers}
                  maxLength={1}
                  inputMode="numeric"
                  type="number"
                  pattern="[0-9]+"
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="otp4" key="otp4" rules={validationRules}>
                <Input
                  className="login-otp-input"
                  onChange={onOtpDigitChange}
                  onKeyDown={preventNonNumbers}
                  maxLength={1}
                  inputMode="numeric"
                  type="number"
                  pattern="[0-9]+"
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="otp5" key="otp5" rules={validationRules}>
                <Input
                  className="login-otp-input"
                  onChange={onOtpDigitChange}
                  onKeyDown={preventNonNumbers}
                  maxLength={1}
                  inputMode="numeric"
                  type="number"
                  pattern="[0-9]+"
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="otp6" key="otp6" rules={validationRules}>
                <Input
                  className="login-otp-input"
                  onKeyDown={preventNonNumbers}
                  maxLength={1}
                  inputMode="numeric"
                  type="number"
                  pattern="[0-9]+"
                />
              </Form.Item>
            </Col>
          </Row>
          {otpSuccessMessage && (
            <Row className="mt--12">
              <Col span={12} className="otp-success-message">
                {otpSuccessMessage}
              </Col>
            </Row>
          )}
          <Row className="mt--12">
            <Col span={12} className="ant-form-item-explain-error">
              {otpError}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[12, 12]} align="middle">
        <Col
          xs={6}
          sm={6}
          md={6}
          lg={6}
          xl={resendOtpContainerWidth}
          xxl={resendOtpContainerWidth}
          className="login-otp-resend-label"
        >
          Didn&apos;t get the code?
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={resendOtpContainerWidth} xxl={resendOtpContainerWidth}>
          <Button
            type="text"
            onClick={resendOtpCallback}
            className={`login-otp-resend-btn ${resendOtpTimer > 0 ? "click-not-allowed" : ""}`}
            icon={<MailOutlined />}
          >
            <Text className="login-otp-resend-btn" strong>
              {getResendTimer()}
            </Text>
          </Button>
        </Col>
      </Row>
    </>
  )
}
