/**
 *
 * @returns string url
 */
export const getDocumentTypes = ({ queryParamString = "" }) =>
  `/user/kyc/v1/get-ekyc-options-external?${queryParamString}`
/**
 *
 * @returns string url
 */
export const getOcrForm = ({ queryParamString = "" }) =>
  `/user/kyc/v1/fetch-patient-config-external?${queryParamString}`
/**
 *
 * @returns string url
 */
export const uploadOcrFile = () => `/ocr/upload-ocr-file`
/**
 *
 * @returns string url
 */
export const submitOCRFormURL = `/user/kyc/v1/extract-image-data`
