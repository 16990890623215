import moment from "moment"

export const onChangeCalculateAnotherFieldValue = ({ formObj, calculationConfig = {} }) => {
  const { calculation = "" } = calculationConfig
  const targetFieldKey = calculationConfig?.target_field || ""
  if (calculation === "addDaysToDate") {
    const dateFieldKey = calculationConfig?.date_field_key || ""
    const daysFieldKey = calculationConfig?.days_field_key || ""
    if (dateFieldKey && daysFieldKey) {
      const dateFieldValue = formObj?.getFieldValue(dateFieldKey) || ""
      const daysFieldValue = formObj?.getFieldValue(daysFieldKey) || 0
      if (dateFieldValue && daysFieldValue) {
        const targetValue = moment(dateFieldValue)?.add("days", daysFieldValue)
        formObj.setFieldValue(targetFieldKey, targetValue)
      }
    }
  }
}
