// Login form Validation rules
export const validationRules = {
  email: [
    {
      required: true,
      type: "email",
      message: "Enter a valid email id",
    },
  ],
  otp: [
    {
      required: true,
      message: "",
    },
    {
      pattern: /^\d{1,1}$/,
      message: "",
    },
  ],
  role: [
    {
      required: true,
      message: "Please select a role",
    },
  ],
  password: [
    { required: true, message: "Please input your password" },
    { min: 8, message: "Password must be at least 8 characters long" },
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
      message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
    },
  ],
  confirmPassword: [
    {
      required: true,
      message: "Please confirm your new password!",
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve()
        }
        return Promise.reject(new Error("The two passwords do not match!"))
      },
    }),
  ],
}

export const GENERIC_ERROR_MESSAGE = "Something went wrong"
export const OTP_RESEND_MESSAGE = "You will soon receive an email with the OTP"
export const USER_NOT_EXIST_MESSAGE = "User does not exist."

export const OTP_RESEND_TIME = 30

// Login form steps enums
export const EMAIL_STEP = "EMAIL"
export const OTP_STEP = "OTP"
export const SELECT_ROLE_STEP = "SELECT_ROLE"
