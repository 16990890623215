import { useCallback, useState } from "react"
import { SmileOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { notification } from "antd"
import { useNavigate } from "react-router-dom"
import { useAxios } from "./useAxios"
import { GENERIC_MESSAGE } from "../../constants/common"

const REQUEST_STATES = {
  unInitialized: "unInitialized",
  loading: "loading",
  complete: "complete",
  error: "error",
  skipped: "skipped",
}

const showNotification = (type, message, description, icon) => {
  notification[type]({
    message,
    description,
    icon,
  })
}

/**
 * @typedef {Object} MutateState
 * @property {boolean} loading - loading
 * @property {REQUEST_STATES} state - state
 */
/**
 * @description Custom hook to make post and put api call using axios
 * @param {{showSuccessNotification:boolean}} config
 * @returns {[mutate, mutateState:MutateState]} response
 */
export const useMutateData = ({ showSuccessNotification = false } = {}) => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(REQUEST_STATES.unInitialized)
  const { axios } = useAxios()
  const navigate = useNavigate()

  const handleErrors = useCallback(
    (err) => {
      const { response } = err
      const statusCode = response?.status
      const errorMessage =
        response?.data?.error?.message || response?.statusText || GENERIC_MESSAGE.DEFAULT_ERROR

      if (
        statusCode === 401 ||
        errorMessage === "Invalid User." ||
        errorMessage === "Missing Authentication."
      ) {
        showNotification(
          "error",
          "Error",
          errorMessage || GENERIC_MESSAGE["401_ERROR"],
          <CloseCircleOutlined className="error-text-color" />
        )
        navigate("/logout")
      } else {
        showNotification(
          "error",
          `Error ${statusCode}`,
          errorMessage,
          <CloseCircleOutlined className="error-text-color" />
        )
      }
    },
    [navigate]
  )

  const mutate = useCallback(
    async ({ url, payload, method, axiosConfig = {} }) => {
      setState(REQUEST_STATES.loading)
      setLoading(true)
      try {
        const response = await axios.request({ method, url, data: payload, ...axiosConfig })
        setState(REQUEST_STATES.complete)
        const successMessage = response.data?.message || response.data?.data?.message
        if (showSuccessNotification && successMessage) {
          showNotification("success", "Success", successMessage, <SmileOutlined />)
        }
        return response
      } catch (err) {
        handleErrors(err)
        setState(REQUEST_STATES.error)
        return {
          data: { error: true, message: err.response?.data?.error?.message || GENERIC_MESSAGE.DEFAULT_ERROR },
        }
      } finally {
        setLoading(false)
      }
    },
    [axios, handleErrors, showSuccessNotification]
  )

  return [mutate, { loading, state }]
}
