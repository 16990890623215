import { useState, useEffect } from "react"
import { Button, notification } from "antd"
import { FileTextOutlined } from "@ant-design/icons"
import { useMutateData } from "../../../utils/hooks"
import { getPresignedUrlApi } from "../../../api/endpoints"
import { Loader } from "../../Loader"

export const PresignedUrlFileIcon = (props) => {
  const {
    documentId = "",
    showLoader = false,
    showAsText = false,
    text = "",
    buttonStyle = {},
    icon = <FileTextOutlined />,
    staticLink = null,
  } = props
  const [apiCall, { loading }] = useMutateData()
  const [toastMessage, contextHolder] = notification.useNotification()
  const [url, setUrl] = useState("")
  const openFileInNewTab = () => url && window.open(url, "_blank").focus()
  const showError = (data) => {
    const { message = "" } = data
    toastMessage.error({
      key: "downloadPresignedFileIconError",
      message: "Error",
      description: message,
    })
  }
  const getPresignedUrl = async () => {
    if (documentId) {
      try {
        const { data = {} } = await apiCall({
          url: getPresignedUrlApi({ queryParamString: new URLSearchParams({ id: documentId }) }),
          method: "GET",
        })
        const presignedUrl = data?.presigned_url || ""
        if (presignedUrl) {
          setUrl(presignedUrl)
        }
      } catch (err) {
        showError(err)
      }
    } else if (staticLink) {
      setUrl(staticLink)
      openFileInNewTab()
    } else {
      showError({ message: "File is not available" })
    }
  }

  useEffect(() => {
    openFileInNewTab()
  }, [url])
  return (
    <>
      {contextHolder}
      {showLoader && loading && <Loader size={60} type="semiTransparentFullPage" />}
      <Button icon={showAsText ? "" : icon} type="text" onClick={getPresignedUrl} style={buttonStyle}>
        {text}
      </Button>
    </>
  )
}
