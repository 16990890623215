export const EXPORT_TABLE_PERMISSIONS = {
  ae_data: "DASHBOARD_EXPORT",
  claim_data: "DASHBOARD_EXPORT",
  counselling_data: "DASHBOARD_EXPORT",
  lab_order_data: "DASHBOARD_EXPORT",
  paid_order_data: "DASHBOARD_EXPORT",
  foc_order_data: "DASHBOARD_EXPORT",
  patient_data: "DASHBOARD_EXPORT",
  pv_data: "DASHBOARD_EXPORT",
  patient_program_data: "DASHBOARD_EXPORT",
  master_doctor_data: "DASHBOARD_EXPORT",
  master_hospital_data: "DASHBOARD_EXPORT",
  master_distributor_data: "DASHBOARD_EXPORT",
  master_lab_data: "DASHBOARD_EXPORT",
  ekyc_data: "DASHBOARD_EXPORT",
}
