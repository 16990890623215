import React, { Suspense } from "react"
import { useSelector } from "react-redux"
import { Route, Routes, Navigate, useParams } from "react-router-dom"
import { ROUTES } from "../../constants/routes"
import { ROUTE_NOT_ALLOWED } from "../../constants/common"
import { Loader } from "../../components/Loader"
import { getCurrentSubdomain } from "../../utils/common"
import { CommunicationDashboard } from "../../components/DLS/CommunicationDashboard"
import CommunicationDetails from "../../components/DLS/CommunicationDetails"
import { CallLogsDashboard } from "../../components/DLS/CallLogsDashboard"

const {
  COMMUNICATION: {
    children: { EMAIL, SMS, EMAIL_DETAILS_PAGE, SMS_DETAILS_PAGE, CALL_LOGS_DASHBOARD },
  },
} = ROUTES
console.log(ROUTES)
const CommunicationPage = () => {
  const params = useParams()
  const { appslug = "" } = params
  const isRoleSwitchInProgress = window.localStorage.getItem("roleSwitchInProgress") || false
  const currentSubdomain = useSelector(getCurrentSubdomain)
  if (appslug !== currentSubdomain && !isRoleSwitchInProgress) {
    return <Navigate to={ROUTE_NOT_ALLOWED} />
  }

  return (
    <Suspense fallback={<Loader type="fullPage" size={60} />}>
      <Routes>
        <Route path={EMAIL.path} element={<CommunicationDashboard type="EMAIL" />} />
        <Route path={SMS.path} element={<CommunicationDashboard type="SMS" />} />
        <Route path={EMAIL_DETAILS_PAGE.path} element={<CommunicationDetails type="EMAIL" />} />
        <Route path={SMS_DETAILS_PAGE.path} element={<CommunicationDetails type="SMS" />} />
        <Route path={CALL_LOGS_DASHBOARD.path} element={<CallLogsDashboard />} />
      </Routes>
    </Suspense>
  )
}

export default CommunicationPage
