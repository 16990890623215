import { Link } from "react-router-dom"

export const getInternalLink = ({ link, text }) => (
  <Link to={link} className="internal-link">
    {text}
  </Link>
)

export const getPatientProgramDetailsPageURL = ({ patientId, programId, currentSubdomain }) =>
  `/${currentSubdomain}/patient/view-program/${patientId}/${programId}`

export const getClaimsDashboardURL = ({ currentSubdomain }) => `/${currentSubdomain}/claims/dashboard`

export const getClaimDetailsPageFromDashboardURL = ({ currentSubdomain, claimId, programId, patientId }) =>
  `/${currentSubdomain}/claims/view-claim-details/${patientId}/${claimId}/${programId}`

export const getClaimDetailsPageFromTableURL = ({ currentSubdomain, claimId, programId, patientId }) =>
  `/${currentSubdomain}/claims/view-patient-claim-details/${patientId}/${claimId}/${programId}`

export const getClaimDetailsPageFromDashboardLink = ({
  currentSubdomain,
  claimId,
  programId,
  patientId,
  text,
}) => {
  const link = getClaimDetailsPageFromDashboardURL({ currentSubdomain, claimId, programId, patientId })
  return getInternalLink({ link, text })
}

export const getClaimDetailsPageFromTableLink = ({
  currentSubdomain,
  claimId,
  programId,
  patientId,
  text,
}) => {
  const link = getClaimDetailsPageFromTableURL({ currentSubdomain, claimId, programId, patientId })
  return getInternalLink({ link, text })
}

export const getPatientProgramDetailsPageLink = ({ text, patientId, programId, currentSubdomain }) => {
  const link = getPatientProgramDetailsPageURL({ patientId, programId, currentSubdomain })
  return getInternalLink({ link, text })
}

export const getPatientProfilePageLink = ({ text, patientId, currentSubdomain }) => {
  const link = `/${currentSubdomain}/patient/view/${patientId}`
  return getInternalLink({ link, text })
}

export const getClaimDetailsPageLink = ({ text, claimId, programId, currentSubdomain }) => {
  const link = `/${currentSubdomain}/claims/view/${claimId}/${programId}`
  return getInternalLink({ link, text })
}

export const getDefaultDataTableRenderFn = (value) => value
