import { Row } from "antd"
import { useSelector } from "react-redux"
import { CallLogsTable } from "./CallLogsTable"
import { canViewCallsDashboard } from "../../../utils/common"
import { Error } from "../../Error"
import "../CommunicationDashboard/style.scss"

export const CallLogsDashboard = () => {
  const hasAccess = useSelector(canViewCallsDashboard)
  if (!hasAccess) {
    return <Error errorStatus={404} />
  }
  return (
    <div className="communication-dashboard-container">
      <Row className="communication-dashboard-table-container h-100">
        <CallLogsTable />
      </Row>
    </div>
  )
}
