import { useEffect, useState } from "react"
import { array, func, string } from "prop-types"
import moment from "moment"
import { Button, Form, Input, Row, Col, Select, DatePicker, TimePicker } from "antd"
import { getRules } from "../DynamicForm/FormSectionBuilder/constants"
import "./styles.scss"

const { Option } = Select

export const ColumnFilter = (props) => {
  const {
    placeholder,
    name,
    onSubmit,
    initialValues = {},
    close,
    searchConfig = "",
    type = "",
    showCustomDropdown,
    showCustomTimer,
    showCustomCalendar,
    customDropdownOptions = {},
    dropdownOptions = [],
    resetFieldsFlag = false,
  } = props
  const [form] = Form.useForm()
  const [triggerReset, setTriggerReset] = useState(resetFieldsFlag)

  useEffect(() => {
    if (triggerReset) form.setFieldValue(name, null)
  }, [triggerReset])

  const processDataObject = (data) => {
    const newData = { ...data }
    const isDateType = searchConfig && searchConfig?.field_type === "date"
    if ((name.includes("_date") || isDateType) && newData[name] instanceof moment) {
      const formattedDate = newData[name].format("YYYY-MM-DD")
      newData[name] = formattedDate
    } else if (name.includes("_time") && newData[name] instanceof moment) {
      const formattedTime = newData[name].format("HH:mm")
      newData[name] = formattedTime
    }

    return newData
  }

  const onFinish = (data) => {
    const newData = processDataObject(data, name)
    const val = (typeof newData[name] === "string" && newData[name]?.trim()) || newData[name]
    if (val) {
      onSubmit(name, val)
      if (triggerReset) setTriggerReset(false)
      close()
    }
  }
  const onReset = () => {
    form.setFieldValue(name, null)
    onSubmit(name, "")
    close()
  }
  useEffect(() => {
    if (!showCustomDropdown) form.setFieldValue(name, "")
  }, [type, form, name])

  const getSelectedComponent = (componentsMap) => {
    switch (true) {
      case showCustomDropdown:
        return componentsMap.dropdown
      case showCustomCalendar:
        return componentsMap.calendar
      case showCustomTimer:
        return componentsMap.timer
      default:
        return componentsMap.default
    }
  }

  const getFormElements = () => {
    const optionsData = (customDropdownOptions && customDropdownOptions[name]) || dropdownOptions
    const { valueProp = "name", timeFormat = "HH:mm", use12Hours = false } = searchConfig
    const formItemProps = {
      name,
      key: name,
      rules: searchConfig !== "" ? getRules(searchConfig) : [],
    }

    const componentsMap = {
      dropdown: (
        <Form.Item {...formItemProps}>
          <Select name={name} placeholder={placeholder} allowClear defaultValue={form[name]}>
            {optionsData?.map((option) => (
              <Option key={option?.id} value={option?.[valueProp]} label={option?.name}>
                {option?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ),
      calendar: (
        <Form.Item {...formItemProps}>
          <DatePicker name={name} placeholder={placeholder} value={form[name]} />
        </Form.Item>
      ),
      timer: (
        <Form.Item {...formItemProps}>
          <TimePicker
            name={name}
            use12Hours={use12Hours}
            format={timeFormat}
            placeholder={placeholder}
            value={form[name]}
          />
        </Form.Item>
      ),
      default: (
        <Form.Item {...formItemProps}>
          <Input name={name} placeholder={placeholder} className="dashboard-search-inputbox" />
        </Form.Item>
      ),
    }

    return getSelectedComponent(componentsMap)
  }

  return (
    <div className="dashboard-search-popup">
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
        <Row>
          <Col span={12}>{getFormElements()}</Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Button onClick={onReset} className="dashboard-search-btn dashboard-search-discard">
              Discard
            </Button>
          </Col>
          <Col span={6}>
            <Button className="dashboard-search-btn" type="primary" htmlType="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

ColumnFilter.defaultProps = {
  placeholder: "",
  name: "",
  onSubmit: () => {},
  initialValues: [],
  close: () => {},
  searchConfig: "",
  type: "",
}

ColumnFilter.propTypes = {
  placeholder: string,
  name: string,
  onSubmit: func,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: array,
  close: func,
  searchConfig: string,
  type: string,
}
