/**
 *
 * @returns string url
 */
export const fetchProgramSteps = () => `/program/getSteps`
/**
 *
 * @returns string url
 */
export const fetchProgramStepOptions = () => `/program/getOptions`
/**
 *
 * @returns string url
 */
export const applyProgramUrl = `/crm/patient/v1/apply-program`
/**
 *
 * @returns string url
 */
export const editPrescriptionUrl = () => `/program/editPrescription`
/**
 *
 * @returns string url
 */
export const getPatientProgramDetailsUrl = ({ queryParamString }) =>
  `/crm/patient/v1/fetch-program-details?${queryParamString}`
/**
 *
 * @returns string url
 */
export const getPresignedUrlApi = ({ queryParamString }) =>
  `/crm/master/v1/generate-presigned-url?${queryParamString}`
/**
 *
 * @returns string url
 */
export const approvePatientProgramMappingApi = `/crm/patient/v1/approve-reject-ppa`
/**
 *
 * @returns string url
 */
export const rejectPatientProgramMappingApi = `/crm/patient/v1/approve-reject-ppa`
/**
 *
 * @returns string url
 */
export const patientProgramDashboardApi = `/crm/patient/v1/patient-program-dashboard`
/**
 *
 * @returns string url
 */
export const getCreateOrderFormApi = `/crm/orders/v1/fetch-order-requirements`
/**
 *
 * @returns string url
 */
export const getOrderOptionsApi = () => `/program/getOrderOptionsList`
/**
 *
 * @returns string url
 */
export const createOrderApi = `/crm/orders/v1/create-order`
/**
 *
 * @returns string url
 */
export const suspendDocumentApi = `/crm/patient/v1/file-suspend`
/**
 *
 * @returns string url
 */
export const updateOrderStatus = () => `/program/updateOrderStatus`
/**
 *
 * @returns string url
 */
export const fetchOrdersDashboardUrl = () => `/program/fetchOrdersDashboard`
/**
 *
 * @returns string url
 */
export const digitiseProgramUrl = `/crm/programs/v1/digitize-patient-program`
/**
 *
 * @returns string url
 */
export const digitiseOrderUrl = `/crm/orders/v1/digitize-order`
