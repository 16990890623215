import { useState, useEffect, useMemo } from "react"
import { useColumnsFormatting } from "../../DataTableWithPagination/useColumnsFormatting"
import { useMutateData } from "../../../../utils/hooks"
import { getFetchCallLogsDashboardApiUrl } from "../../../../api/endpoints/communication"
import { DataTableWithPagination } from "../../DataTableWithPagination"

export const CallLogsTable = () => {
  const [tableData, setTableData] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [tableColumns, setTableColumns] = useState([])
  const [getFormattedColumns] = useColumnsFormatting()
  const [apiCall, { loading }] = useMutateData({ showNotification: false })
  const [searchParams, setSearchParams] = useState({})
  const memorisedSearchParams = useMemo(() => searchParams, [searchParams])
  const getFormattedParams = (params = {}) => {
    let formattedParams = {}
    formattedParams = {
      ...params,
      page_no: params?.page_number || params?.page_no || 0,
      page_size: params?.page_size || 10,
    }
    delete formattedParams.page_number
    return formattedParams
  }
  const getData = async (params = {}) => {
    const formattedParams = getFormattedParams(params)
    const { data = {} } = await apiCall({
      url: getFetchCallLogsDashboardApiUrl({ queryParamString: new URLSearchParams(formattedParams) }),
      method: "GET",
    })
    setTableData(data?.call_logs_data || [])
    setTotalRecords(data?.total_count || data?.call_logs_data?.length || 0)
    setTableColumns(data?.display_columns || [])
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <DataTableWithPagination
      data={tableData}
      totalDataCount={totalRecords}
      columns={getFormattedColumns({ columns: tableColumns })}
      api={getData}
      loading={loading}
      otherParams={memorisedSearchParams}
      setOtherParams={setSearchParams}
      disableSticky
      type="callLogs"
    />
  )
}
