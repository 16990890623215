/**
 *
 * @returns string url
 */
export const createPatientUrl = `/crm/patient/v1/create`
/**
 *
 * @returns string url
 */
export const getPatientDashboardUrl = () => `/patient/all`
/**
 *
 * @returns string url
 */
export const getPatientByUidUrl = ({ queryParamString }) => `/crm/patient/v1/get?${queryParamString}`
/**
 *
 * @returns string url
 */
export const editPatientByUidUrl = `/crm/patient/v1/update`
/**
 *
 * @returns string url
 */
export const uploadPatientFileUrl = () => `/patient/upload`

export const updatePatientStausUrl = `/crm/patient/v1/update-patient-status`

/**
 *
 * @returns string url
 */
export const getPatientForm = ({ queryParamString }) =>
  `/crm/patient/v1/get-patient-forms?${queryParamString}`
/**
 *
 * @returns string url
 */
export const fetchPatientDropdownOptions = () => `/patient/fetchPatientDropdownOptions`
/**
 *
 * @returns string url
 */
export const fetchEligibleProgramsUrl = ({ queryParamString }) =>
  `/crm/programs/v1/fetch-programs?${queryParamString}`
/**
 *
 * @returns string url
 */
export const fetchAdverseEventFieldsUrl = () => `/patient/fetchAdverseEventFields`
/**
 *
 * @returns string url
 */
export const createAdverseEventUrl = `/crm/adverse-events/v1/create-adverse-event`
/**
 *
 * @returns string url
 */
export const approveAdverseEventUrl = `/crm/adverse-events/v1/approve-adverse-event`
/**
 *
 * @returns string url
 */
export const closeAdverseEventUrl = `/crm/adverse-events/v1/close-adverse-event`
/**
 *
 * @returns string url
 */
export const editAdverseEventUrl = `/crm/adverse-events/v1/edit-adverse-event`
/**
 *
 * @returns string url
 */
export const labOrderFormUrl = () => `/patient/labOrderForm`
/**
 *
 * @returns string url
 */
export const getPatientNotesDataUrl = ({ queryParamString }) =>
  `/crm/v1/notes/get-patient-notes?${queryParamString}`

/**
 *
 * @returns string url
 */
export const fetchNotesFormUrl = `/crm/v1/notes/fetch-notes-form`

/**
 *
 * @returns string url
 */
export const saveNotesUrl = `/crm/v1/notes/save-notes`

/**
 *
 * @returns string url
 */
export const scheduleSampleUrlApi = `/crm/orders/v1/schedule-sample-pickup`
