/**
 *
 * @returns string url
 */
export const createTokenEmailUrl = `/user/v1/login`

/**
 *
 * @returns string url
 */
export const forgotPasswordUrl = `/user/v1/forgot-password`

/**
 *
 * @returns string url
 */
export const resetPasswordUrl = `/user/v1/reset-password`

/**
 *
 * @returns string url
 */
export const verifyPasswordResetLink = `/user/v1/verify-reset-link`

/**
 *
 * @returns string url
 */
export const verifyTokenEmailUrl = () => `/auth/verify_token_email`

/**
 *
 * @returns string url
 */
export const selectAppRoleUrl = () => `/auth/selectAppRole`

/**
 *
 * @returns string url
 */
export const logoutUrl = () => `/auth/logout`
/**
 *
 * @returns string url
 */
export const getRSAPublicKeyUrl = `/user/v1/public_key`
