import { lazy } from "react"
import { Error } from "../components/Error"
import { LogoutPage } from "../pages/LogoutPage"
import { GuestPage } from "../pages/GuestPage"
import { PhleboPage } from "../pages/PhleboPage"
import PhysicalVerificationPage from "../pages/PhysicalVerificationPage"
import CommunicationPage from "../pages/CommunicationPage"
import { LabOrdersPage } from "../pages/LabOrdersPage"

// Lazy loading pages
const LoginPage = lazy(() => import("../pages/LoginPage"))
const ResetPassword = lazy(() => import("../pages/LoginPage/ResetPassword"))
const { PUBLIC_ROUTE, PRIVATE_ROUTE, GUEST_ROUTE, PV_PRIVATE } = require("../constants/common")

const PatientPage = lazy(() => import("../pages/PatientPage"))
const MasterManagementPage = lazy(() => import("../pages/MasterManagementPage"))
const FieldCounsellorPage = lazy(() => import("../pages/FieldCounsellorPage"))
const EKycPage = lazy(() => import("../pages/EKycPage"))
const VisitorPage = lazy(() => import("../pages/VisitorPage"))
const ClaimsPage = lazy(() => import("../pages/ClaimsPage"))
const CounsellingPage = lazy(() => import("../pages/CounsellingPage"))

// Array of top level routes to lazy load components
export const ROUTES = [
  {
    name: "login",
    path: "/login",
    component: LoginPage,
    route: PUBLIC_ROUTE,
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: ResetPassword,
    route: PUBLIC_ROUTE,
  },
  {
    name: "logout",
    path: "/logout",
    component: LogoutPage,
    route: PUBLIC_ROUTE,
  },
  {
    name: "doctor-consent",
    path: "/doctor-consent/*",
    route: GUEST_ROUTE,
    component: GuestPage,
  },
  {
    name: "visitor",
    path: "/visitor/*",
    route: GUEST_ROUTE,
    component: VisitorPage,
  },
  {
    name: "phlebo",
    path: "/phlebo/*",
    route: PV_PRIVATE,
    component: PhleboPage,
  },
  {
    name: "patient",
    path: "/:appslug/patient/*",
    route: PRIVATE_ROUTE,
    component: PatientPage,
  },
  {
    name: "master",
    path: "/:appslug/master-management/*",
    route: PRIVATE_ROUTE,
    component: MasterManagementPage,
  },
  {
    name: "communication",
    path: "/:appslug/communication/*",
    route: PRIVATE_ROUTE,
    component: CommunicationPage,
  },
  {
    name: "lab-orders",
    path: "/:appslug/lab-orders/*",
    route: PRIVATE_ROUTE,
    component: LabOrdersPage,
  },
  {
    name: "fc",
    path: "/:appslug/fc/*",
    route: PRIVATE_ROUTE,
    component: FieldCounsellorPage,
  },
  {
    name: "pv",
    path: "/:appslug/pv/*",
    route: PRIVATE_ROUTE,
    component: PhysicalVerificationPage,
  },
  {
    name: "ekyc",
    path: "/:appslug/ekyc/*",
    route: PRIVATE_ROUTE,
    component: EKycPage,
  },
  {
    name: "claims",
    path: "/:appslug/claims/*",
    route: PRIVATE_ROUTE,
    component: ClaimsPage,
  },
  {
    name: "counselling",
    path: "/:appslug/counselling/*",
    route: PRIVATE_ROUTE,
    component: CounsellingPage,
  },
  {
    name: "500",
    path: "/500",
    route: PUBLIC_ROUTE,
    component: Error,
    errorStatus: 500,
  },
  {
    name: "403",
    path: "/403",
    route: PRIVATE_ROUTE,
    component: Error,
    errorStatus: 403,
  },
]
