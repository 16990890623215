/**
 *
 * @returns string url
 */
export const getAdverseEventsDashboardUrl = ({ queryParamString }) =>
  `/crm/adverse-events/v1/get-adverse-event-data?${queryParamString}`

/**
 *
 * @returns string url
 */
export const getAdverseEventsOptions = ({ queryParamString }) =>
  `/crm/adverse-events/v1/fetch-adverse-event-options?${queryParamString}`

/**
 *
 * @returns string url
 */
export const getAePdfPreviewUrl = () => `/adverseEvents/getPreview`

/**
 *
 * @returns string url
 */
export const rejectAeUrl = `/crm/adverse-events/v1/reject-adverse-event`

/**
 *
 * @returns string url
 */
export const sendAeUrl = `/crm/adverse-events/v1/adverse-events-send-email`
