/**
 *
 * @returns string url
 */
export const verifyPvOrderUrl = `/crm/physical_verification/v1/validate-pv-order_id`
/**
 *
 * @returns string url
 */
export const fetchPatientDetailsForPv = `/crm/physical_verification/v1/get-patient-details`
/**
 *
 * @returns string url
 */
export const fetchPvFormUrl = ({ queryParamString }) =>
  `/crm/physical_verification/v1/pv-form?${queryParamString}`
/**
 *
 * @returns string url
 */
export const sendPvOtp = `/crm/physical_verification/v1/generate-pv-otp`
/**
 *
 * @returns string url
 */
export const updatePv = `/crm/physical_verification/v1/update-pv`
/**
 *
 * @returns string url
 */
export const createPvUrl = `/crm/physical_verification/v1/schedule-pv`
/**
 *
 * @returns string url
 */
export const editPvUrl = `/crm/physical_verification/v1/edit-pv`
/**
 *
 * @returns string url
 */
export const getPvDataTableUrl = ({ queryParamString }) =>
  `/crm/physical_verification/v1/pv-dashboard?${queryParamString}`
/**
 *
 * @returns string url
 */
export const getPvDropdownOptionsUrl = `/crm/physical_verification/v1/get-fulfiller-options`
