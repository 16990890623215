import { useEffect, useState } from "react"
import { bool, string } from "prop-types"
import { Button, notification } from "antd"
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons"
import { getTextField } from "../FormSectionBuilder/fields"
import { useMutateData } from "../../../../utils/hooks"
import { uploadPatientFileUrl, suspendDocumentApi } from "../../../../api/endpoints"
import { Loader } from "../../../Loader"
import { uploadOcrFile } from "../../../../api/endpoints/ocr"
import { validateFileSizeType } from "../../../../utils/common"
import "./styles.scss"
import { PresignedUrlFileIcon } from "../../PresignedUrlFileIcon"

export const DocumentUpload = (props) => {
  const {
    name = "",
    patientId = "",
    programId = "",
    appId = "",
    appUid = "",
    ocrFileType = "",
    parentFormObj,
    isEdit = false,
    dependencies = [],
    multipleMode = false,
    maxFileCount = 1,
    allowedFileExtensions = [],
    maxFileSizeInMB = 0,
  } = props
  const existingDocumentIdValue = parentFormObj.getFieldValue(name)
  const [documentId, setDocumentId] = useState("")
  const [fileNames, setFileNames] = useState({})
  const getNumberOfFilesUploaded = () => documentId?.length
  const hasMaxNoOfFilesUploaded = () => {
    const noOfFilesUploaded = getNumberOfFilesUploaded()
    const maxReached = noOfFilesUploaded === maxFileCount
    return maxReached
  }
  const getMaxMoreAllowed = () => {
    const noOfFilesUploaded = getNumberOfFilesUploaded()
    const maxMoreAllowed = maxFileCount - noOfFilesUploaded
    return maxMoreAllowed
  }
  useEffect(() => {
    if (existingDocumentIdValue && typeof documentId === "string" && !documentId) {
      if (typeof existingDocumentIdValue === "object") {
        setDocumentId(existingDocumentIdValue)
      } else {
        setDocumentId([existingDocumentIdValue])
      }
    }
  }, [existingDocumentIdValue])
  const [showLoader, setShowLoader] = useState(false)
  const fileInputId = `file_${name}`
  const [toastMessage, contextHolder] = notification.useNotification()
  const [apiCall] = useMutateData()
  const hiddenTextField = getTextField({ hide: true })
  const showToastMessage = ({ message = "", key = "", error = false }) => {
    if (error) {
      toastMessage.error({
        key,
        message: "Error",
        description: message,
      })
    } else {
      toastMessage.success({
        key,
        message: "Success",
        description: message,
      })
    }
  }

  const validateFields = () => parentFormObj.validateFields([name, ...dependencies])
  const suspendDocument = async ({ docId = "" }) => {
    const { data = {} } = await apiCall({
      url: suspendDocumentApi,
      method: "POST",
      payload: {
        id: docId,
        patient_id: patientId,
        program_id: programId,
      },
    })
    showToastMessage(data)
  }
  const resetHiddenFileInput = () => {
    document.getElementById(fileInputId).value = ""
  }
  const updateParentForm = () => {
    const noOfFilesUploaded = getNumberOfFilesUploaded()
    const isFilePresent = noOfFilesUploaded > 0
    if (isFilePresent) {
      const valueToUpdate = multipleMode ? documentId : documentId[0]
      parentFormObj.setFieldValue(name, valueToUpdate)
    } else {
      parentFormObj.setFieldValue(name, null)
    }
    validateFields()
  }
  useEffect(() => {
    updateParentForm()
  }, [documentId])
  const uploadFile = (params) =>
    new Promise((resolve) => {
      const { file } = params
      const formData = new FormData()
      formData.append("type", name)
      formData.append("file", file)
      if (patientId !== "") {
        formData.append("patientId", patientId)
      }
      if (programId) {
        formData.append("programId", programId)
      }
      if (isEdit) {
        formData.append("isEdit", true)
      }
      if (ocrFileType) {
        formData.append("ocrFileType", ocrFileType)
        formData.append("applicationId", appId)
        formData.append("applicationUid", appUid)
      }
      let uploadUrl
      if (ocrFileType) {
        uploadUrl = uploadOcrFile()
      } else {
        uploadUrl = uploadPatientFileUrl()
      }
      apiCall({
        url: uploadUrl,
        payload: formData,
        method: "POST",
      })
        .then(({ data }) => {
          const fileId = data?.id || data?.file || ""
          if (fileId) {
            setFileNames({
              ...fileNames,
              [fileId]: file?.name || "",
            })
          }
          if (!data.error) {
            showToastMessage(data)
          }
          resolve(fileId)
        })
        .catch(() => {
          resolve("")
        })
    })
  const uploadMultipleFiles = (files = []) => {
    setShowLoader(true)
    const allUploads = files?.map((file) => uploadFile({ file }))
    Promise.all(allUploads).then((result) => {
      const successUploads = result?.filter((successFile) => successFile)
      const updatedFileId = [...documentId, ...successUploads]
      setDocumentId(updatedFileId)
      setShowLoader(false)
      resetHiddenFileInput()
    })
  }
  const removeFileFromFe = ({ fileId }) => {
    const updatedFilesList = new Set(documentId)
    updatedFilesList.delete(fileId)
    setDocumentId([...updatedFilesList])
  }
  const removeFile = ({ fileId }) => {
    setShowLoader(true)
    if (!ocrFileType) {
      suspendDocument({ docId: fileId })
    }
    removeFileFromFe({ fileId })
    setShowLoader(false)
  }
  const getUploadedFiles = () => {
    const filesToDisplay = documentId
    return filesToDisplay?.map((fileId, index) => {
      const fileName = fileNames[fileId] || `${name} ${index + 1}`
      return (
        <div>
          <PresignedUrlFileIcon
            documentId={ocrFileType ? "" : fileId}
            text={fileName}
            staticLink={ocrFileType ? fileId : null}
            showLoader
            showAsText
            buttonStyle={{ color: "#6495ed", padding: 0 }}
          />
          &nbsp;
          <Button
            type="text"
            onClick={() => {
              removeFile({ fileId })
            }}
            style={{ padding: 0, color: "red" }}
          >
            <DeleteOutlined />
          </Button>
        </div>
      )
    })
  }
  const openFilesWindowToUpload = () => {
    document.getElementById(fileInputId).click()
  }
  const getUploadButtonText = () => {
    const noOfFilesUploaded = getNumberOfFilesUploaded()
    const maxReached = hasMaxNoOfFilesUploaded()
    const fileNoun = noOfFilesUploaded > 1 ? "files" : "file"
    let uploadButtonText = ""
    uploadButtonText = "'Click this area' or 'Drag and drop here' to upload"
    if (maxReached) {
      uploadButtonText = `${noOfFilesUploaded} ${fileNoun} uploaded`
    } else if (noOfFilesUploaded > 0) {
      uploadButtonText = "'Click this area' or 'Drag and drop here' to upload more"
    }
    return uploadButtonText
  }
  const getUploadButton = () => {
    const uploadButtonText = getUploadButtonText()
    const maxReached = hasMaxNoOfFilesUploaded()
    const cursorClass = maxReached ? "document-upload-not-allowed" : "document-upload-allowed"
    const onClick = maxReached ? () => {} : openFilesWindowToUpload
    return (
      <Button className={`document-upload-btn-container ${cursorClass}`} onClick={onClick}>
        <div>{uploadButtonText}</div>
        {!maxReached && (
          <div className="document-upload-icon">
            <UploadOutlined />
          </div>
        )}
      </Button>
    )
  }
  const getValidFiles = ({ uploadedFiles = [] }) => {
    const mappedFiles = [...Array(uploadedFiles.length).keys()].map((i) => uploadedFiles[i])
    const validFiles = mappedFiles?.filter((file) => {
      const { isValid = false, message = "" } = validateFileSizeType({
        fileObj: file,
        maxFileSizeInMB,
        allowedFileExtensions,
      })
      if (!isValid && message) {
        showToastMessage({ error: true, message })
      }
      return isValid
    })
    return validFiles
  }
  const onFileInputChange = (event) => {
    const uploadedFiles = event?.target?.files || event?.dataTransfer?.files || []
    const maxMoreAllowed = getMaxMoreAllowed()
    const fileNoun = maxMoreAllowed > 1 ? "files" : "file"
    let message = `Maximum of ${maxMoreAllowed} more ${fileNoun} allowed`
    if (maxMoreAllowed === 0) {
      message = "No more upload allowed"
    }
    if (uploadedFiles.length > maxMoreAllowed) {
      showToastMessage({ error: true, message })
      resetHiddenFileInput()
    } else {
      const validFiles = getValidFiles({ uploadedFiles })
      uploadMultipleFiles(validFiles)
    }
  }
  const onDrop = (e) => {
    e.preventDefault()
    onFileInputChange(e)
  }
  const onDragOver = (e) => {
    e.preventDefault()
  }
  return (
    <div onDrop={onDrop} onDragOver={onDragOver}>
      {contextHolder}
      {showLoader && <Loader size={60} type="semiTransparentFullPage" />}
      {hiddenTextField}
      <input
        type="file"
        id={fileInputId}
        style={{ display: "none" }}
        accept={allowedFileExtensions.join(",")}
        onChange={onFileInputChange}
        multiple={multipleMode}
      />
      {getUploadButton()}
      {documentId && getUploadedFiles()}
    </div>
  )
}

DocumentUpload.defaultProps = {
  name: "",
  patientId: "",
  programId: "",
  isEdit: false,
}

DocumentUpload.propTypes = {
  name: string,
  patientId: string,
  programId: string,
  isEdit: bool,
}
