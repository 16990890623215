/**
 *
 * @returns string url
 */
export const labDataURL = `/crm/orders/v1/get-orders`
/**
 *
 * @returns string url
 */
export const getPatientLabOrderDataUrl = ({ queryParamString = "" }) =>
  `/crm/orders/v1/fetch-patient-orders?${queryParamString}`
