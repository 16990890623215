/**
 *
 * @returns string url
 */
export const getCounsellingDashboardApiURL = ({ queryParamString }) =>
  `/crm/v1/counselling/dashboard?${queryParamString}`
/**
 *
 * @returns string url
 */
export const getCounsellingFormsApiURL = ({ queryParamString }) =>
  `/crm/fetch-dynamic-form/programs/v1/fetch-program-requirements?${queryParamString}`
/**
 *
 * @returns string url
 */
export const counsellingActionURL = `/crm/v1/counselling/process-counselling`
