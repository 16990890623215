import { Input, Checkbox } from "antd"
import { onChangeCalculateAnotherFieldValue } from "./utils"

export const getTextField = (details) => {
  const { placeholder = "", hide = false, formType = "", formObj } = details
  const fieldLabel = details?.step_name_text || ""
  const updatedPlaceholder = placeholder || `Enter ${fieldLabel}`
  const disableDuringEdit = details?.disable_during_edit || false
  let disabled = ""
  disabled = false
  if (
    (formType === "editPatient" ||
      formType === "adverseEventEditForm" ||
      formType === "physicalVerificationForm") &&
    disableDuringEdit
  ) {
    disabled = true
  }
  if (details?.non_editable) {
    disabled = true
  }
  return (
    <Input
      disabled={disabled}
      placeholder={updatedPlaceholder}
      style={hide ? { display: "none" } : {}}
      onChange={() => {
        if (details?.on_change_calculate_field) {
          onChangeCalculateAnotherFieldValue({
            calculationConfig: details?.on_change_calculate_field,
            formObj,
          })
        }
      }}
    />
  )
}

export const getDefaultCheckbox = ({ label = "Yes" }) => <Checkbox>{label}</Checkbox>

export const getMultiSelectCheckboxGroup = ({ options, field }) => {
  const optionsList = options?.map((option) => ({
    label: option?.name || "",
    value: option?.id || "Yes",
  }))
  const { direction = "" } = field
  const checkboxGroupClass = direction === "vertical" ? "verticalCheckboxList" : ""
  return <Checkbox.Group options={optionsList} name={field?.step_name || ""} className={checkboxGroupClass} />
}

export const getCheckbox = ({ field, options }) => {
  const defaultCheckbox = getDefaultCheckbox({})
  const isMultiSelect = field?.multiselect || false
  if (isMultiSelect) {
    const checkboxGroup = getMultiSelectCheckboxGroup({ field, options })
    return checkboxGroup
  }
  return defaultCheckbox
}
