import { useSelector } from "react-redux"

export const useEncryption = () => {
  const defaultPublicKey = useSelector((state) => state.user?.rsaPublicKey)

  const StringToArrayBuffer = (str) => {
    const buf = new ArrayBuffer(str.length)
    const bufView = new Uint8Array(buf)
    for (let i = 0, strLen = str.length; i < strLen; i += 1) {
      bufView[i] = str.charCodeAt(i)
    }
    return buf
  }

  const arrayBufferToString = (buf) => String.fromCharCode.apply(null, new Uint8Array(buf))

  const formatPublicKey = async (keyWithHeaderFooter) => {
    const publicKeyHeader = "-----BEGIN PUBLIC KEY-----"
    const publicKeyFooter = "-----END PUBLIC KEY-----"
    const keyContent = keyWithHeaderFooter.substring(
      publicKeyHeader.length,
      keyWithHeaderFooter.length - publicKeyFooter.length
    )
    const decodedString = window.atob(keyContent)
    const stringArrayBuffer = StringToArrayBuffer(decodedString)
    const key = await window.crypto.subtle.importKey(
      "spki",
      stringArrayBuffer,
      {
        name: "RSA-OAEP",
        hash: "SHA-256",
      },
      true,
      ["encrypt"]
    )
    return key
  }

  const encryptRSA = async (plaintext, params = {}) => {
    const key = params.publicKey || defaultPublicKey
    if (!key) {
      throw new Error("Public key is not set.")
    }
    const formattedKey = await formatPublicKey(key)
    try {
      const encrypted = await window.crypto.subtle.encrypt(
        {
          name: "RSA-OAEP",
          hash: "SHA-256",
        },
        formattedKey,
        new TextEncoder().encode(plaintext)
      )
      const encryptedBase64 = window.btoa(arrayBufferToString(encrypted))
      return encryptedBase64
    } catch (error) {
      throw new Error(error)
    }
  }
  return encryptRSA
}
export default useEncryption
