/**
 *
 * @returns string url
 */
export const getClaimDashboardURL = ({ queryParamString }) => `/crm/claims/v1/claims?${queryParamString}`
/**
 *
 * @returns string url
 */
export const getPatientClaimsTableURL = ({ queryParamString }) =>
  `/crm/claims/v1/fetch-patient-claims?${queryParamString}`
/**
 *
 * @returns string url
 */
export const getClaimsDetailsURL = ({ queryParamString }) =>
  `/crm/claims/v1/claim-details?${queryParamString}`
/**
 *
 * @returns string url
 */
export const getClaimFormsURL = ({ queryParamString }) =>
  `/crm/fetch-dynamic-form/claims/v1/fetch-claim-requirements?${queryParamString}`
/**
 *
 * @returns string url
 */
export const getClaimsOptionsURL = ({ queryParamString }) =>
  `/crm/claims/v1/fetch-claim-options?${queryParamString}`

export const CLAIM_ACTION_API_MAP = {
  SHARE_DOCUMENTS_WITH_TPA: "/crm/claims/v1/share-documents-with-tpa",
  CONFIRMATION_FROM_TPA: "/crm/claims/v1/confirmation-received-from-tpa",
  CLAIM_DISBURSEMENT: "/crm/claims/v1/claim-disbursement",
  CLOSE_CLAIM: "/crm/claims/v1/close-claim",
}
