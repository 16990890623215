/**
 *
 * @returns string url
 */
export const fetchFormDataUrl = ({ queryParamString }) =>
  `/user/master/v1/get-doctor-consent-form?${queryParamString}`
/**
 *
 * @returns string url
 */
export const sendOTPUrl = `/user/master/v1/generate-doctor-consent-otp`
/**
 *
 * @returns string url
 */
export const verifyOTPUrl = `/user/master/v1/add-doctor-consent`
