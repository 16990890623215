import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Row, Col, Button, Dropdown, Menu } from "antd"
import { EditOutlined, MoreOutlined } from "@ant-design/icons"
import { sections, generatePatientProfileMenuItems } from "./constants"
import { getAddressSectionObj, getCaregiverList } from "../../CreatePatientForm/constants"
import {
  formatDate,
  isCreatePatientInteractionAllowed,
  isEditPatientAllowed,
  isFcModuleEnabled,
  isTagFcEnabled,
} from "../../../../utils/common"
import { EditPatientForm } from "../../EditPatientForm"
import { DeliveryAddressCard } from "../DeliveryAddressCard"
import { FCModalContainer } from "../../FCModalContainer"
import { PatientInteractionForm } from "../../PatientInteractionForm"
import { getAddress } from "./common"
import { PresignedUrlFileIcon } from "../../PresignedUrlFileIcon"

export const PatientProfileSummary = (props) => {
  const [summary, setSummary] = useState({})
  const fcModuleEnabled = useSelector(isFcModuleEnabled)
  const tagFcEnabled = useSelector(isTagFcEnabled)
  const createPatientInteractionEnabled = useSelector(isCreatePatientInteractionAllowed)
  const editPatientAllowed = useSelector(isEditPatientAllowed)
  const { data = {}, source = "", editCallback = () => {}, reloadPatientProfilePage, patientId } = props
  const [editSectionIndex, setEditSectionIndex] = useState("")
  const [editDeliveryAddressIndex, setEditDeliveryAddressIndex] = useState("")
  const [showTagFCModal, setShowTagFcModal] = useState(false)
  const [showPatientInteractionForm, setShowPatientInteractionForm] = useState(false)
  const patientProfileMenuItems = generatePatientProfileMenuItems({
    tagFcEnabled,
    createPatientInteractionEnabled,
  })

  const isCreateFlow = source === "createFlow"
  const afterEditPatient = () => {
    setEditDeliveryAddressIndex("")
    reloadPatientProfilePage()
  }
  useEffect(() => {
    if (isCreateFlow) {
      const patientInfo = data?.patient_info || {}
      const addressInfo = data?.address_info || {}
      const registrationInfo = data?.registration_info || {}
      const caregiverInfo = data?.caregiver_info || {}
      const addressSection = getAddressSectionObj({
        data: addressInfo,
        isBothSameAddr: addressInfo?.isBothSameAddr !== "No",
      })
      const summaryData = {
        ...patientInfo,
        ...registrationInfo,
        ...addressInfo,
        care_giver_details: getCaregiverList(caregiverInfo),
        ...addressSection,
      }
      setSummary(summaryData)
    } else if (JSON.stringify(data) !== JSON.stringify(summary)) {
      setSummary(data)
    }
  }, [props])
  const onEditClick = ({ editIndex, editIndexAfterCreate }) => {
    if (source === "createFlow") {
      editCallback({ editIndex })
    } else {
      setEditSectionIndex(editIndexAfterCreate)
    }
  }
  const getAddressCard = (addrDetails) => {
    const { recordIndex, address } = addrDetails
    const delAddrSection = summary?.display_columns?.find(
      (section) => section?.subKey && section.subKey === "delivery_addresses"
    )
    return (
      <DeliveryAddressCard
        address={address}
        isCreateFlow={isCreateFlow}
        index={recordIndex}
        editPatientAllowed={editPatientAllowed}
        triggerEdit={({ addrIndex = "" }) => {
          if (addrIndex !== "") {
            setEditDeliveryAddressIndex(addrIndex)
          }
          onEditClick(delAddrSection)
        }}
        patientId={props?.patientId}
        afterSave={afterEditPatient}
        allAddresses={summary?.delivery_addresses || []}
      />
    )
  }
  const getFields = (container, fields, recordIndex) => {
    const formattedFields = fields?.map((field) => {
      let value = ""
      value = "--"
      const { key = "", renderFn = "", span = 3 } = field
      if (key && container[key]) {
        value = container[key] || ""
        if (renderFn === "date" && value) {
          value = formatDate(value)
        }
        if (renderFn === "file" && value) {
          value = <PresignedUrlFileIcon documentId={value} showLoader />
        }
      }
      value = value || "--"
      return (
        <Col span={span}>
          {field.label && <div className="patient-profile-summary-field-label">{field.label}</div>}
          {field.key && <div className="patient-profile-summary-field-value">{value}</div>}
          {field.render && field.render === "getAddress" && getAddress(container)}
          {field.render &&
            field.render === "getAddressCard" &&
            getAddressCard({ address: container, recordIndex })}
        </Col>
      )
    })
    return formattedFields
  }
  const quitEditMode = () => {
    setEditDeliveryAddressIndex("")
    setEditSectionIndex("")
  }
  const getSection = (details, index) => {
    const {
      sectionLabel = "",
      hasSubKey = false,
      subKey = "",
      hasMultipleItems = false,
      fields = [],
      editIndex = "",
      editIndexAfterCreate = "",
      buttonText = "",
      buttonOperation = "",
      buttonMaxCountCheck = "",
    } = details

    if (
      fcModuleEnabled &&
      (sectionLabel === "Personal Details" || sectionLabel === "General Info") &&
      !fields.some((field) => field.key === "tagged_fc_id") &&
      !isCreateFlow
    )
      fields.push({ label: "Tagged FC", key: "tagged_fc_id" })
    const isLastSection = sections.length - 1 === index
    const container = hasSubKey ? summary[subKey] || [] : summary
    const eIndex = isCreateFlow ? editIndex : editIndexAfterCreate
    const isSectionBeingEdited = editSectionIndex === eIndex
    const showSection = true
    // if (hasMultipleItems && container.length === 0) {
    //   showSection = false
    // }
    const showEditForDeliveryAddress = sectionLabel !== "Delivery Address" || isCreateFlow
    const enableEditOption = editPatientAllowed && !isSectionBeingEdited && showEditForDeliveryAddress
    // enableEditOption = false
    return (
      showSection && (
        <div>
          <div className="patient-profile-summary-section-name">
            {sectionLabel}
            {enableEditOption && (
              <Button
                icon={<EditOutlined />}
                onClick={() => onEditClick({ editIndex, editIndexAfterCreate })}
                type="text"
                className="patient-profile-edit-icon"
              />
            )}
          </div>
          {!isSectionBeingEdited && (
            <div className="patient-profile-summary-section-content">
              <Row gutter={[24, 24]}>
                {!hasMultipleItems && getFields(container, fields)}
                {hasMultipleItems &&
                  container.map((record, recordIndex) => getFields(record, fields, recordIndex))}
              </Row>
              {editPatientAllowed &&
                buttonText &&
                !isCreateFlow &&
                (buttonOperation === "edit" || container.length < buttonMaxCountCheck) && (
                  <Row gutter={[24, 24]} className="mt-24">
                    <Col span={3}>
                      <Button
                        className="custom-secondary-btn"
                        onClick={() => onEditClick({ editIndex, editIndexAfterCreate })}
                      >
                        {buttonText}
                      </Button>
                    </Col>
                  </Row>
                )}
            </div>
          )}
          {isSectionBeingEdited && (
            <EditPatientForm
              patientId={props?.patientId}
              editIndex={editIndexAfterCreate}
              discardCallback={quitEditMode}
              afterSave={afterEditPatient}
              patientData={summary}
              editDeliveryAddressIndex={editDeliveryAddressIndex}
            />
          )}
          {!isLastSection && <div className="patient-profile-summary-section-divider" />}
        </div>
      )
    )
  }

  const hideModal = () => setShowTagFcModal(false)

  const renderModal = () => (
    <FCModalContainer
      hideParentModal={hideModal}
      patientId={patientId}
      fetchProfile={reloadPatientProfilePage}
      dataObj={summary}
    />
  )

  const handlePatientInteractionFormFlag = (value) => setShowPatientInteractionForm(value)

  const renderPatientInteractionForm = () => (
    <PatientInteractionForm
      patientId={patientId}
      showPatientInteractionForm={handlePatientInteractionFormFlag}
    />
  )

  const handleDropdownItemClick = (details) => {
    const { key = " " } = details
    if (key === "tag_fc") setShowTagFcModal(true)
    if (key === "add_patient_interaction") handlePatientInteractionFormFlag(true)
  }

  return (
    <>
      {!showPatientInteractionForm &&
        patientProfileMenuItems.length > 0 &&
        fcModuleEnabled &&
        !isCreateFlow &&
        !props?.disableMenuOptions && (
          <div className="patient-profile-summary-dropdown-container">
            <Dropdown
              overlay={
                <Menu
                  items={patientProfileMenuItems}
                  onClick={(clickDetails) => {
                    handleDropdownItemClick(clickDetails)
                  }}
                />
              }
              placement="bottomRight"
              overlayClassName="custom-fc-dropdown"
            >
              <MoreOutlined />
            </Dropdown>
          </div>
        )}
      <div className="patient-profile-summary-container">
        {!showPatientInteractionForm && summary?.display_columns?.map(getSection)}
        {showTagFCModal && renderModal()}
        {showPatientInteractionForm && renderPatientInteractionForm()}
      </div>
    </>
  )
}
