import { Button } from "antd"
import { FileTextOutlined } from "@ant-design/icons"
import { PresignedUrlFileIcon } from "../components/DLS/PresignedUrlFileIcon"
import { formatDate, formatTime, formatTimeAsAmPm } from "../utils/common"
import { StatusTag } from "../components/DLS/AdverseEventsDashboard/utils/statusTag"

export const getFileIcon = ({ value }) => value && <PresignedUrlFileIcon documentId={value} showLoader />

export const renderArrayToCSV = ({ value }) => value && Array.isArray(value) && value?.join(", ")

export const getExternalLink = ({ value }) => {
  const { url = value } = value
  return url && <Button icon={<FileTextOutlined />} type="link" href={url} target="_blank" />
}

export const renderFunctions = {
  date: (value) => value && formatDate(value),
  time: (value) => value && formatTime(value),
  timeAsAmPm: (value) => value && formatTimeAsAmPm(value),
  file: (value) => value && getFileIcon({ value }),
  fileList: (fileList = []) => Array.isArray(fileList) && fileList?.map((value) => getFileIcon({ value })),
  externalLink: (value) => getExternalLink({ value }),
  booleanToYesNo: (value) => (value ? "Yes" : "No"),
  statusTag: ({ text = "", color = "", backgroundColor = "" }) => (
    <StatusTag text={text} color={color} backgroundColor={backgroundColor} />
  ),
  arrayToCSV: (value) => renderArrayToCSV({ value }),
}

export const defaultRenderFunction = (value) => value

export const getRenderFunction = ({ renderFn = "" }) => {
  const renderFunction =
    renderFn && renderFunctions?.[renderFn] ? renderFunctions?.[renderFn] : defaultRenderFunction
  return renderFunction
}

export const commonRenderFunctions = {
  ...renderFunctions,
}
