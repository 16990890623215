import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  user: null,
  rsaPublicKey: null,
}

/**
 * @description create user redux object{actions,name,reducers}
 */
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login(state, action) {
      return { ...state, user: action.payload }
    },
    logout() {
      return initialState
    },
    publicKey(state, action) {
      return { ...state, rsaPublicKey: action.payload }
    },
  },
})
export const { login, logout, publicKey } = userSlice.actions
